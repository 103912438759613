import { Injectable } from '@angular/core'
import { PostgrestResponse, SupabaseClient } from '@supabase/supabase-js'
import { SupabaseService } from '@core/services/supabase.service'
import { Observable, Subscriber } from 'rxjs'
import { Post } from '../models/post.model'
import { endOfYesterday, startOfTomorrow } from 'date-fns'

@Injectable({
    providedIn: 'root',
})
export class PostsService {
    private supabase!: SupabaseClient

    constructor(private supabaseService: SupabaseService) {
        this.supabase = this.supabaseService.getClient()
    }

    // Create a function to handle inserts
    handlePostInserts = (payload: any) => {
        console.log('Change received!', payload)
    }

    livePostList(): void {
        this.supabase
            .channel('schema-db-changes')
            .on(
                'postgres_changes',
                {
                    event: '*',
                    schema: 'public',
                    table: 'posts',
                },
                (payload) => this.handlePostInserts(payload),
            )
            .subscribe()
    }

    postList(): Observable<Post[]> {
        return new Observable((subscriber: Subscriber<Post[]>) => {
            this.supabase
                .from('posts')
                .select('*, site:sites(*), guard:profiles!guard_id(*), ro:profiles!created_by(*)')
                .lte('created_at', startOfTomorrow().toISOString())
                .gte('created_at', endOfYesterday().toISOString())
                .then((result: PostgrestResponse<Post>) => subscriber.next(result.data!))
        })
    }
}
