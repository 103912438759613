import { createFeature, createReducer, on } from '@ngrx/store'
import { postListActions } from './actions'
import { PostListState } from '../models/post.model'

const initialPostListState: PostListState = {
    postListIsLoading: false,
    posts: [],
}

const postListFeature = createFeature({
    name: 'postList',
    reducer: createReducer(
        initialPostListState,
        on(postListActions.requestPostList, (state) => ({ ...state, postListIsLoading: true })),
        on(postListActions.requestPostListSuccess, (state, event) => ({
            ...state,
            posts: event.posts,
            postListIsLoading: false,
        })),
        on(postListActions.requestPostListFailed, (state, event) => ({
            ...state,
            postListIsLoading: false,
            error: event.error,
        })),
    ),
})

export const {
    name: postListFeatureKey,
    reducer: postListReducer,
    selectPostListIsLoading,
    selectPosts,
} = postListFeature
