import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { Post } from '../models/post.model'

export const postListActions = createActionGroup({
    source: 'postList',
    events: {
        'Request Post List': emptyProps(),
        'Request Post List Success': props<{ posts: Post[] }>(),
        'Request Post List Failed': (error: any) => ({ error }),
    },
})
